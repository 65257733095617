import React from 'react';

function ChatModelSelector({
  dropdownRef,
  showModelDropdown,
  setShowModelDropdown,
  selectedModel,
  availableModels,
  handleModelSelect,
}) {
  return (
    <div
      className={`model-selection ${showModelDropdown ? 'active' : ''}`}
      onClick={() => setShowModelDropdown((prevState) => !prevState)}
      ref={dropdownRef}
    >
      <div className="model-selected">
        {selectedModel}
        <i className="bi bi-chevron-down chevron-down"></i>
      </div>
      {showModelDropdown && (
        <ul className="model-dropdown">
          {availableModels.map((model) => (
            <li
              key={model.name}
              className="model-option"
              onClick={(event) => handleModelSelect(model.name, event)}
            >
              <img
                src={model.icon}
                alt={`${model.name} icon`}
                className={`model-icon ${model.iconClass}`}
              />
              <div className="model-info">
                <span className="model-name">{model.name}</span>
                <span className="model-description">{model.description}</span>
              </div>
              {selectedModel === model.name && (
                <i className="bi bi-check-circle-fill model-selected-check"></i>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default ChatModelSelector;