import React, { createContext, useContext, useState, useEffect } from 'react';

// Create an Auth Context
const AuthContext = createContext(null);

// Hook to use the Auth Context
export function useAuth() {
  return useContext(AuthContext);
}

// Utility to get a specific cookie by name
const getCookie = (name) => {
  const cookies = document.cookie.split('; ').reduce((acc, cookie) => {
    const [key, value] = cookie.split('=');
    acc[key] = value;
    return acc;
  }, {});
  return cookies[name] || null;
};

// AuthProvider Component
export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(undefined); // User state
  const [token, setToken] = useState(localStorage.getItem('jwtToken')); // JWT token state
  const [plan, setPlan] = useState(localStorage.getItem('userPlan')); // Plan state
  const [loading, setLoading] = useState(true); // Loading state

  const validateSession = () => {
    console.log('Validating user session...');
    fetch('/api/validate_session/', {
      method: 'GET',
      credentials: 'include', // Include cookies
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error('Network response was not ok: ' + response.statusText);
        }
        return response.json();
      })
      .then((data) => {
        if (data.isAuthenticated) {
          console.log('Session is valid:', data);
          setUser(data.user);
          setToken(data.jwt);
          localStorage.setItem('jwtToken', data.jwt);
  
          // Store plan directly from the response
          const userPlan = data.plan; // Access plan directly
          console.log('Retrieved user plan from response:', userPlan);
          if (userPlan) {
            setPlan(userPlan);
            localStorage.setItem('userPlan', userPlan); // Store in local storage
            console.log('Stored user plan in local storage:', userPlan);
          }
        } else {
          console.log('Session is invalid.');
          setUser(null);
          setToken(null);
          setPlan(null);
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('userPlan');
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error validating session:', error);
        setUser(null);
        setToken(null);
        setPlan(null);
        localStorage.removeItem('jwtToken');
        localStorage.removeItem('userPlan');
        setLoading(false);
      });
  };  

  // Reauthenticate user with JWT
  const reauthenticateWithJWT = () => {
    if (token) {
      fetch(`/api/reauthenticate_with_jwt/?token=${encodeURIComponent(token)}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      })
        .then((response) => response.json())
        .then((data) => {
          if (data.isAuthenticated) {
            console.log('JWT re-authentication successful:', data);
            setUser(data.user);

            // Retrieve and store the plan from cookies
            const userPlan = getCookie('user_plan');
            if (userPlan) {
              console.log('User plan:', userPlan);
              setPlan(userPlan);
              localStorage.setItem('userPlan', userPlan);
            }
          } else {
            console.log('JWT is invalid.');
            setUser(null);
            setToken(null);
            setPlan(null);
            localStorage.removeItem('jwtToken');
            localStorage.removeItem('userPlan');
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error('JWT re-authentication failed:', error);
          setUser(null);
          setToken(null);
          setPlan(null);
          localStorage.removeItem('jwtToken');
          localStorage.removeItem('userPlan');
          setLoading(false);
        });
    } else {
      console.log('No JWT token available for re-authentication.');
      setUser(null);
      setToken(null);
      setPlan(null);
      localStorage.removeItem('jwtToken');
      localStorage.removeItem('userPlan');
      setLoading(false);
    }
  };

  // Run session validation on component mount
  useEffect(() => {
    console.log('AuthProvider mounted. Running session validation...');
    validateSession();
  }, []);  

  // Provide the context to children components
  return (
    <AuthContext.Provider value={{ user, setUser, token, setToken, plan, setPlan, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;