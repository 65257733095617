import React from 'react';

function ChatInput({ inputText, handleInputChange, handleSendMessage }) {
  const handleKeyDown = (event) => {
    if (event.key === 'Enter' && inputText.trim() !== '') {
      handleSendMessage();
    }
  };

  return (
    <>
      <input
        type="text"
        placeholder="Message Copilot"
        value={inputText}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown} // Add the onKeyDown handler here
        className="chat-input-field"
      />
      <div className="chat-button-wrapper">
        <button
          className={`chat-send-button ${inputText ? 'has-text' : ''}`}
          style={{ cursor: inputText ? 'pointer' : 'default' }}
          onClick={handleSendMessage}
        >
          <i className="chat-send-icon bi bi-arrow-up-circle-fill"></i>
        </button>
      </div>
    </>
  );
}

export default ChatInput;