class ChatService {
    constructor({ onMessage }) {
      this.onMessage = onMessage;
      this.ws = null;
      this.isOpen = false;
      this.openPromise = null;
  
      this.connect();
    }
  
    connect() {
      this.ws = new WebSocket('wss://dev.chromecopilot.ai/ws/chat-feature/');
      this.openPromise = new Promise((resolve) => {
        this.ws.onopen = () => {
          console.log('WebSocket connected');
          this.isOpen = true;
          resolve();
        };
      });
  
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data);
        if (this.onMessage) {
          this.onMessage(data);
        }
      };
  
      this.ws.onclose = () => {
        console.log('WebSocket disconnected');
        this.isOpen = false;
      };
  
      this.ws.onerror = (error) => {
        console.error('WebSocket error:', error);
      };
    }
  
    // Attempt to reconnect if the socket is closed
    reconnect() {
      if (!this.isOpen) {
        console.log("Reconnecting WebSocket...");
        this.connect(); 
        return this.ensureWebSocketIsOpen();
      }
      return Promise.resolve();
    }
  
    ensureWebSocketIsOpen() {
      if (this.isOpen) {
        return Promise.resolve();
      }
      return this.openPromise;
    }
  
    sendFullMessage(messages, model, token, personality, context) {
      const payload = {
        token: token,
        type: 'chat_message',
        messages: messages.map(({ content, role }) => ({ content, role })),
        model: model,
        personality: personality,
        context: context,
        isWebExplainer: false,
        level: '',
        isTranslate: false
      };
  
      if (this.isOpen && this.ws.readyState === WebSocket.OPEN) {
        this.ws.send(JSON.stringify(payload));
      } else {
        console.warn("WebSocket not open. Unable to send message.");
      }
    }
  
    close() {
      if (this.ws) {
        this.ws.close();
      }
    }
  }
  
  export default ChatService;  