import React, { useEffect, useRef } from 'react';

function MessageList({ messages }) {
  const messageListRef = useRef(null);

  useEffect(() => {
    // Scroll to the bottom whenever messages change
    if (messageListRef.current) {
      messageListRef.current.scrollTop = messageListRef.current.scrollHeight;
    }
  }, [messages]);

  return (
    <div className="message-list" ref={messageListRef}>
      {messages.map((msg, index) => (
        <div className={`message ${msg.role}`} key={index}>
          <div className={`message-content ${msg.role === 'assistant' ? 'streaming' : ''}`}>
            {msg.content}
          </div>
        </div>
      ))}
    </div>
  );
}

export default MessageList;
