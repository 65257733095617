import React, { useState, useEffect, useRef } from 'react';
import Sidebar from '../Sidebar/Sidebar';
import { useSidebar } from '../Sidebar/SidebarContext';
import { useAuth } from '../helpers/AuthProvider';
import ChatWindow from './ChatWindow';
import ChatService from './ChatService';
import { marked } from 'marked';
import hljs from 'highlight.js';
import './Chat.css';

import { ReactComponent as ChatIcon } from '../../assets/images/sidebar-svgrepo-com.svg';
import { ReactComponent as SettingsIcon } from '../../assets/images/new-svgrepo-com.svg';
import openaiLogo from '../../assets/images/openai-logo.svg';
import claudeIcon from '../../assets/images/claude-icon.svg';
import geminiIcon from '../../assets/images/gemini-icon.svg';
import geminiProIcon from '../../assets/images/gemini-pro-icon.svg';

function Chat() {
  const { setSidebarContent } = useSidebar();
  const { plan } = useAuth();
  const [inputText, setInputText] = useState('');
  const [selectedModel, setSelectedModel] = useState('GPT-4o mini');
  const [showModelDropdown, setShowModelDropdown] = useState(false);
  const [tooltipTimeout, setTooltipTimeout] = useState(null);
  const [showSidebarTooltip, setShowSidebarTooltip] = useState(false);
  const [showSidebarChatTooltip, setShowSidebarChatTooltip] = useState(false);
  const [showTopChatTooltip, setShowTopChatTooltip] = useState(false);
  const [isSidebarVisible, setIsSidebarVisible] = useState(
    JSON.parse(localStorage.getItem('sidebarVisible')) ?? true
  );

  const dropdownRef = useRef(null);
  const [messages, setMessages] = useState([]);
  const assistantMessageBufferRef = useRef('');
  const lastAssistantMessageRef = useRef(null);

  // Custom renderer for marked.js
  const renderer = new marked.Renderer();
  renderer.code = (code, lang) => {
    const isLanguage = lang && hljs.getLanguage(lang);
    const languageName = isLanguage ? lang : 'plaintext';
    const highlightedCode = isLanguage
      ? hljs.highlight(lang, code).value
      : hljs.highlightAuto(code).value;

    return `
      <div class="code-container">
        <div class="code-header">
          <span class="code-language">${languageName}</span>
          <div class="code-actions">
            <button class="copy-code-button"><i class="fa-regular fa-copy"></i> Copy code</button>
          </div>
        </div>
        <pre><code class="${languageName}">${highlightedCode}</code></pre>
      </div>
    `;
  };

  marked.setOptions({
    renderer,
    breaks: true,
    headerIds: false,
    mangle: false,
    smartypants: false,
  });

  const allModels = [
    {
      name: 'GPT-4o mini',
      description: 'Optimized for minimal latency, suitable for quick tasks.',
      icon: openaiLogo,
      iconClass: 'openai-icon',
    },
    {
      name: 'GPT-4o',
      description: 'Standard version of GPT-4 with comprehensive capabilities.',
      icon: openaiLogo,
      iconClass: 'openai-pro-icon',
    },
    {
      name: 'Claude 3 Haiku',
      description: 'Specialized for concise, poetic outputs.',
      icon: claudeIcon,
      iconClass: 'claude-haiku-icon',
    },
    {
      name: 'Claude 3.5 Sonnet',
      description: 'Enhanced Claude model for detailed responses.',
      icon: claudeIcon,
      iconClass: 'claude-sonnet-icon',
    },
    {
      name: 'Gemini 1.5 Flash',
      description: 'High-speed generation with light moderation.',
      icon: geminiIcon,
      iconClass: 'gemini-icon',
    },
    {
      name: 'Gemini 1.5 Pro',
      description: 'Full-featured generation with advanced capabilities.',
      icon: geminiProIcon,
      iconClass: 'gemini-icon',
    },
  ];

  const availableModels =
    plan === 'Novice'
      ? allModels.filter(
          (model) =>
            !['GPT-4o', 'Claude 3.5 Sonnet', 'Gemini 1.5 Pro'].includes(
              model.name
            )
        )
      : allModels;

  const chatServiceRef = useRef(null);

  // Set up the WebSocket connection once on mount
  useEffect(() => {
    chatServiceRef.current = new ChatService({
      onMessage: (data) => {
        const msgType = data.type || data.message_type;
        console.log("Received message from backend:", data);

        if (msgType === 'current_tokens') {
          console.log("Handling current_tokens message:", data);
        } else if (msgType === 'chat_message') {
          console.log("Handling chat_message chunk:", data.message);
          const chunk = data.message || '';
          assistantMessageBufferRef.current += chunk;

          // Incrementally update the last assistant message in the DOM
          if (lastAssistantMessageRef.current) {
            lastAssistantMessageRef.current.innerHTML = marked.parse(
              assistantMessageBufferRef.current
            );
          }
        } else if (msgType === 'end_of_chat_message') {
          console.log("Handling end_of_chat_message");
          const finalMessage = assistantMessageBufferRef.current.trim();
          if (finalMessage !== '') {
            console.log("Final assistant message:", finalMessage);
            // Since the chunks are already streamed and rendered incrementally,
            // there is no need to append the finalMessage again.
            assistantMessageBufferRef.current = ''; // Reset the ref
            lastAssistantMessageRef.current = null; // Reset the reference
          }
          if (chatServiceRef.current) {
            console.log("Closing WebSocket after end_of_chat_message");
            chatServiceRef.current.close();
          }        
        } else if (msgType === 'ping') {
          console.log("Handling ping message");
        } else {
          console.log("Unhandled message type:", msgType, data);
        }
      }
    });

    return () => {
      if (chatServiceRef.current) {
        chatServiceRef.current.close();
      }
    };
  }, []);

  useEffect(() => {
    localStorage.setItem('sidebarVisible', JSON.stringify(isSidebarVisible));
    if (isSidebarVisible) {
      setShowSidebarTooltip(false);
    } else {
      setShowSidebarTooltip(false);
    }
  }, [isSidebarVisible]);

  useEffect(() => {
    const logoContainer = document.getElementById('studio-logo-container');
    if (logoContainer) {
      logoContainer.classList.add('studio-logo-hidden');
    }

    if (isSidebarVisible) {
      setSidebarContent(
        <div className="sidebar-icon-container">
          <div
            className="sidebar-close-icon-container"
            onMouseEnter={() => {
              clearTimeout(tooltipTimeout);
              setShowSidebarTooltip(true);
            }}
            onMouseLeave={() => {
              const timeout = setTimeout(() => setShowSidebarTooltip(false), 0);
              setTooltipTimeout(timeout);
            }}
            onClick={() => setIsSidebarVisible(false)}
          >
            <ChatIcon className="sidebar-icon chat-svg-icon" />
            {showSidebarTooltip && (
              <div className="sidebar-tooltip fade-in">Close Sidebar</div>
            )}
          </div>

          <div
            className="sidebar-chat-icon-container"
            onMouseEnter={() => setShowSidebarChatTooltip(true)}
            onMouseLeave={() => setShowSidebarChatTooltip(false)}
          >
            <SettingsIcon className="sidebar-icon settings-svg-icon" />
            {showSidebarChatTooltip && (
              <div className="chat-tooltip fade-in">New Chat</div>
            )}
          </div>
        </div>
      );
    } else {
      setSidebarContent('');
    }

    return () => {
      if (logoContainer) {
        logoContainer.classList.add('studio-logo-hidden');
      }
      setSidebarContent('');
      clearTimeout(tooltipTimeout);
    };
  }, [
    isSidebarVisible,
    setSidebarContent,
    showSidebarTooltip,
    tooltipTimeout,
    showSidebarChatTooltip,
  ]);

  const handleInputChange = (event) => {
    setInputText(event.target.value);
    if (event.target.value !== '') {
      clearTimeout(tooltipTimeout);
    }
  };

  const handleModelSelect = (modelName, event) => {
    event.stopPropagation();
    setSelectedModel(modelName);
    setShowModelDropdown(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowModelDropdown(false);
      }
    }
    if (showModelDropdown) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showModelDropdown]);

  const handleSendMessage = async () => {
    if (!chatServiceRef.current) {
      console.log("No ChatService instance available.");
      return;
    }
    const trimmedMessage = inputText.trim();
    if (trimmedMessage === '') return;

    const jwtAccessToken = localStorage.getItem('jwtToken') || '';
    const personality = 'default_personality'; 
    const context = 'default_context';

    const messageToSend = {
      content: trimmedMessage,
      role: 'user'
    };

    console.log("Sending message:", messageToSend);

    // If WebSocket is closed, reconnect
    if (!chatServiceRef.current.isOpen) {
      await chatServiceRef.current.reconnect();
    }

    await chatServiceRef.current.ensureWebSocketIsOpen();

    chatServiceRef.current.sendFullMessage(
      [messageToSend],
      selectedModel,
      jwtAccessToken,
      personality,
      context
    );

    setMessages((prev) => [
      ...prev,
      { content: trimmedMessage, role: 'user' },
      { content: '', role: 'assistant' },
    ]);

    setTimeout(() => {
      const messageElements = document.querySelectorAll(".message.assistant");
      lastAssistantMessageRef.current = messageElements[messageElements.length - 1];
    }, 0);

    setInputText('');
  };

  return (
    <div className="chat-container" style={{ transition: 'all 0.3s ease' }}>
      <div
        style={{
          width: isSidebarVisible ? '240px' : '0px',
          transition: 'width 0.3s ease',
        }}
      >
        {isSidebarVisible && <Sidebar />}
      </div>
      <div
        className="chat-content"
        style={{
          flexGrow: 1,
          transition: 'width 0.3s ease',
        }}
      >
        <ChatWindow
          isSidebarVisible={isSidebarVisible}
          onShowSidebarTooltip={setShowSidebarTooltip}
          showSidebarTooltip={showSidebarTooltip}
          setIsSidebarVisible={setIsSidebarVisible}
          showTopChatTooltip={showTopChatTooltip}
          setShowTopChatTooltip={setShowTopChatTooltip}
          dropdownRef={dropdownRef}
          showModelDropdown={showModelDropdown}
          setShowModelDropdown={setShowModelDropdown}
          selectedModel={selectedModel}
          availableModels={availableModels}
          handleModelSelect={handleModelSelect}
          messages={messages}
          inputText={inputText}
          handleInputChange={handleInputChange}
          handleSendMessage={handleSendMessage}
        />
      </div>
    </div>
  );
}

export default Chat;